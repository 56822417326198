import React from "react"
import { RouteComponentProps } from "@reach/router"
import { Button, Grid, Paper, SvgIcon, TextField, Typography } from "@material-ui/core"
// @ts-ignore
import BGImage from "../../static/galaxy.png"
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles"
import { ReactComponent as EmailSent } from "../../static/email-sent.svg"
import Snackbar from "@material-ui/core/Snackbar"
import { Alert } from "@material-ui/lab"
import { navigate } from "gatsby"
import { postData, testEmail, trimString } from "../services/auth"
import { LOGIN_API } from "../constants"
import { trackCustomEvent } from 'gatsby-plugin-google-analytics'

const useStyles = makeStyles((_: Theme) =>
  createStyles({
    paperContainer: {
      minHeight: "100vh",
      background: "linear-gradient(0deg, rgba(34,193,195,1) 0%, rgba(253,187,45,1) 100%)",
      // backgroundImage: `url(` + BGImage + `)`,
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center center",
      backgroundSize: "cover",
      margin: 0,
      padding: 0,
      backgroundAttachment: "fixed"
    },
    root: {
      display: "flex",
      flexWrap: "wrap"
    },
    brand: {
      fontSize: 60,
      fontFamily: "Archivo Black",
      color: "rgb(0,76,64)",
      marginBottom: 30,
    },
    namastey: {
      fontSize: 30,
      fontWeight: 700,
      marginTop: 20,
      color: "rgb(65,65,65)"
    },
    desc: {
      fontSize: 17,
      fontWeight: 500,
      marginTop: 10,
      textAlign: "center",
      color: "rgb(136,136,136)",
      maxWidth: "70%"
    },
    email: {
      marginTop: 20,
      marginBottom: 10,
      width: "70%",
      textAlign: "center"
    },
    send: {
      justifyItems: "center",
      backgroundColor: "rgb(0,76,64)",
      fontWeight: 600,
      color: "#fff",
      "&:disable": {
        backgroundColor: "rgb(72,169,153)",
        color: "#fff"
      },
      "&:hover": {
        fontWeight: 600,
        backgroundColor: "rgb(0,121,107)",
        color: "rgb(255,255,255)"
      }
    },
    google: {
      marginRight: 20
    }
  })
)

export default function Login(_: RouteComponentProps) {
  const jwt = typeof window !== 'undefined' && localStorage.getItem("jwt")
  if (jwt) {
    typeof window !== `undefined` && navigate("/user")
  }

  const classes = useStyles()
  const [errorValue, setErrorValue] = React.useState(false)
  const [errorOpen, setErrorOpen] = React.useState(false)
  const [newEmailValue, setNewEmailValue] = React.useState("")
  const [checkEmailPage, setCheckEmailPage] = React.useState(false)
  const [open, setOpen] = React.useState(false)

  const handleNewEmail = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setErrorValue(false)
    setNewEmailValue(event.target.value)
  }

  const handleClose = (_?: React.SyntheticEvent, reason?: string) => {
    if (reason === "clickaway") {
      return
    }
    setOpen(false)
    setErrorOpen(false)
  }

  return (
    <Grid container direction={"column"} classes={{ root: classes.paperContainer }}>
      <Grid container style={{ width: "100vw", height: "20ch" }} justify={"center"} alignItems={"flex-end"}>
        <Typography classes={{ root: classes.brand }}> Johari </Typography>
      </Grid>
      <Grid container style={{ width: "100vw" }} className={classes.root} justify={"center"}>
        <Paper style={{ borderRadius: 20, width: "60ch" }} elevation={3}>
          {!checkEmailPage && (
            <Grid container direction={"column"} justify={"center"} alignItems={"center"}>
              <Grid item classes={{ root: classes.namastey }}>
                Welcome!
              </Grid>
              <Grid item container justify={"center"} classes={{ root: classes.desc }}>
                <Typography>
                  Johari doesn’t belive in passwords.
                  We’ll send you the magic link, simple
                  and secure.
                </Typography>
              </Grid>
              <Grid item classes={{ root: classes.email }}>
                <TextField
                  fullWidth
                  error={errorValue}
                  value={newEmailValue}
                  onChange={handleNewEmail}
                  variant={"outlined"}
                  id="outlined-email-add-text"
                  label="Email (name@company.com)"
                  inputMode={"email"}
                  inputProps={{ style: { textAlign: "center" } }}
                />
              </Grid>
              <Grid item style={{marginBottom: 30}}>
                <Button
                  className={classes.send}
                  onClick={() => {
                    setNewEmailValue(trimString(newEmailValue))
                    if (testEmail(newEmailValue)) {
                      // if (generalEmail(newEmailValue)) {
                      //   setErrorOpen(true)
                      //   setErrorValue(true)
                      //   return
                      // }
                      setCheckEmailPage(true)
                      // console.log("logging with this", newEmailValue)
                      postData(LOGIN_API, newEmailValue)
                      trackCustomEvent({
                        // string - required - The object that was interacted with (e.g.video)
                        category: "Login Button",
                        // string - required - Type of interaction (e.g. 'play')
                        action: "Click",
                        // string - optional - Useful for categorizing events (e.g. 'Spring Campaign')
                        // number - optional - Numeric value associated with the event. (e.g. A product ID)
                      })
                      return
                    }
                    setErrorValue(true)
                  }}
                  variant="contained"
                  style={{ width: `43ch`, height: "7ch" }}>Get In</Button>
              </Grid>
              <Snackbar open={errorOpen} autoHideDuration={5000} onClose={handleClose}>
                <Alert onClose={handleClose} severity="error">Please enter your business email.
                  You can add backup personal email in account settings. This is to ensure quality feedback and reduce
                  spams.
                </Alert>
              </Snackbar>
              {/*<Grid item container justify={"center"} alignItems={"flex-end"} style={{ marginTop: 30 }}>*/}
              {/*  OR*/}
              {/*</Grid>*/}

              {/*<Grid item>*/}
              {/*  <Button*/}
              {/*    onClick={() => {*/}
              {/*      console.log("Google Verification opted")*/}
              {/*      //TODO : google verification*/}
              {/*    }}*/}
              {/*    startIcon={<SvgIcon className={classes.google}>*/}
              {/*      <Google/>*/}
              {/*    </SvgIcon>}*/}
              {/*    style={{ width: `43ch`, height: "7ch", marginTop: 20, marginBottom: 20, textTransform: "initial" }}*/}
              {/*  >*/}
              {/*    Sign In with Google</Button>*/}
              {/*</Grid>*/}
            </Grid>
          )}
          {checkEmailPage && (
            <Grid container direction={"column"} justify={"center"} alignItems={"center"}>
              <Grid item classes={{ root: classes.namastey }}>
                Great!
                Check your Email!
              </Grid>
              <Grid item container justify={"center"} classes={{ root: classes.desc }}>
                <Typography>
                  We sent you a secure login/verification link at <b>{newEmailValue}</b>
                </Typography>
              </Grid>
              <Grid item container justify={"center"} alignItems={"flex-end"} style={{ marginTop: 30 }}>
                <SvgIcon style={{ height: 270, width: 270 }}>
                  <EmailSent/>
                </SvgIcon>
              </Grid>
              <Grid item container justify={"flex-end"} style={{ marginBottom: 10 }}>
                <Grid container justify={"flex-end"} style={{ marginRight: 10, marginTop: 20 }}>
                  <Button
                    onClick={() => {
                      postData(LOGIN_API, newEmailValue)
                      setOpen(true)
                    }}
                    style={{ textTransform: "initial", color: "rgb(129,177,199)" }}
                  >
                    Resend Link </Button>
                  <Snackbar open={open} autoHideDuration={5000} onClose={handleClose}>
                    <Alert onClose={handleClose} severity="success">Magic link sent again...</Alert>
                  </Snackbar>
                </Grid>
              </Grid>
            </Grid>
          )}
        </Paper>
      </Grid>
    </Grid>
  )
}